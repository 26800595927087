export default [
    {
        label: '날짜',
        field: 'regDate',
        sort: true
    },
    {
        label: '1',
        field: 'distance_1',
    },
    {
        label: '2',
        field: 'distance_2',
    },
    {
        label: '3',
        field: 'distance_3',
    },
    {
        label: '4',
        field: 'distance_4',
    },
    {
        label: '5',
        field: 'distance_5',
    },
    {
        label: '6',
        field: 'distance_6',
    },
    {
        label: 'total (m)',
        field: 'total',
    },
];

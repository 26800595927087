export default [
    {
        label: '날짜',
        field: 'regDate',
        sort: true
    },
    {
        label: '상지',
        field: 'totalA',
    },
    {
        label: '손목',
        field: 'totalB',
    },
    {
        label: '손',
        field: 'totalC',
    },
    {
        label: '협응력',
        field: 'totalD',
    },
    {
        label: '총점',
        field: 'totalAll',
    },
];

export default [
    {
        label: '날짜',
        field: 'regDate',
        sort: true
    },
    {
        label: 'More-Affected Hand',
        field: 'ma',
    },
    {
        label: 'Less-Affected Hand',
        field: 'la',
    },
];

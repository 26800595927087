export default [
    {
        label: '날짜',
        field: 'regDate',
        sort: true
    },
    {
        label: 'Time(sec)',
        field: 'time',
    },
];

export default [
    {
        label: '날짜',
        field: 'regDate',
        sort: true
    },
    {
        label: 'Aou 총점',
        field: 'totalAouScore',
    },
    {
        label: 'Aou 평균',
        field: 'totalAouAvg',
    },
    {
        label: 'Qom 총점',
        field: 'totalQomScore',
    },
    {
        label: 'Qom 평균',
        field: 'totalQomAvg',
    },
];

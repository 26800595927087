export default [
    {
        label: '날짜',
        field: 'regDate',
        sort: true
    },
    {
        label: '총점',
        field: 'total',
    },
];

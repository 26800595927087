export default [
    {
        label: '날짜',
        field: 'regDate',
        sort: true
    },
    {
        label: 'Assist',
        field: 'assist',
    },
    {
        label: 'self selected speed (m/s)',
        field: 'average_self_selected_speed',
    },
    {
        label: 'fast speed (m/s)',
        field: 'average_fast_speed',
    },
];

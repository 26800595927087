export default [
    {
        label: '날짜',
        field: 'regDate',
        sort: true
    },
    {
        label: '하지',
        field: 'totalE',
    },
    {
        label: '협응력',
        field: 'totalF',
    },
    {
        label: '총점',
        field: 'totalAll',
    },
];

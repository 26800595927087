export default [
    {
        label: '날짜',
        field: 'regDate',
        sort: true
    },
    {
        label: '총평균',
        field: 'totalAvg',
    },
    {
        label: '총합계',
        field: 'totalSum',
    },
];

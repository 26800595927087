<template>
  <div>
    <div class="table-lind"/>
    <mdb-datatable-2
        v-model="data"
        class="text-center chart-data-table"
        :pagination="false"
        v-if="isShowChart"
        noFoundMessage="data-null"/>
    <div class="table-lind"/>
  </div>
</template>

<script>
import {mdbDatatable2} from "mdbvue";
import ftsts_columns from "@/assets/data/statistics/ftsts_columns";

export default {
  name: "StatisticsFtsts",
  components: {
    mdbDatatable2,
  },
  props: {
    isShowChart: Boolean,
    dataTableList: Array,
    selectTool: String,
  },
  data() {
    return {
      data: {
        columns: ftsts_columns,
        rows: []
      },
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const self = this;
      switch (self.selectTool) {
        case 'ftsts':
          for (let i = 0; i < self.dataTableList.length; i++) {
            self.dataTableList[i].tools.ftsts.regDate = self.dataTableList[i].regDate
            self.data.rows.push(self.dataTableList[i].tools.ftsts)
          }
          return
      }
    }
  }
}
</script>

<style scoped>

/deep/ .dataTable th {
  background: #fff !important;
}
/deep/ .chart-data-table tr:last-child {
  background: #F8F8FA;
}
.table-lind {
  width: 100%;
  height: 2px;
  background: #B1B1B1;
}
</style>